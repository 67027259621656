import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../../styles/Admin.css";

function QuestionForm() {
  const { examId, id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    questionText: "",
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
    optionE: "",
    pointA: "",
    pointB: "",
    pointC: "",
    pointD: "",
    pointE: "",
    type: "",
    discussion: "",
    examId: examId || "",
  });
  const [exams, setExams] = useState([]);
  const [loading, setLoading] = useState(true);
  const quillRefs = useRef({});

  const [availablePoints, setAvailablePoints] = useState({
    A: ["0", "1", "2", "3", "4", "5"],
    B: ["0", "1", "2", "3", "4", "5"],
    C: ["0", "1", "2", "3", "4", "5"],
    D: ["0", "1", "2", "3", "4", "5"],
    E: ["0", "1", "2", "3", "4", "5"],
  });

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`/questions/${id}`);
      setData((prevData) => ({ ...prevData, ...response.data }));
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch question", error);
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchExams();
    if (id) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, examId, fetchData]);

  useEffect(() => {
    if (data.type === "TIU" || data.type === "TWK") {
      setAvailablePoints({
        A: ["0", "5"],
        B: ["0", "5"],
        C: ["0", "5"],
        D: ["0", "5"],
        E: ["0", "5"],
      });
      setData((prevData) => ({
        ...prevData,
        pointA: "",
        pointB: "",
        pointC: "",
        pointD: "",
        pointE: "",
      }));
    } else if (data.type === "TKP") {
      setAvailablePoints({
        A: ["1", "2", "3", "4", "5"],
        B: ["1", "2", "3", "4", "5"],
        C: ["1", "2", "3", "4", "5"],
        D: ["1", "2", "3", "4", "5"],
        E: ["1", "2", "3", "4", "5"],
      });
      setData((prevData) => ({
        ...prevData,
        pointA: "",
        pointB: "",
        pointC: "",
        pointD: "",
        pointE: "",
      }));
    }
  }, [data.type]);

  useEffect(() => {
    if (id && !loading) {
      ["A", "B", "C", "D", "E"].forEach((option) => {
        handlePointChange(option, data[`point${option}`] || "");
      });
    }
  }, [id, loading, data]);

  const fetchExams = async () => {
    try {
      const response = await axios.get("/exams");
      setExams(response.data);
    } catch (error) {
      console.error("Failed to fetch exams", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!data.type) {
      Swal.fire("Error", "Please select a question type", "error");
      return;
    }

    const points = ["pointA", "pointB", "pointC", "pointD", "pointE"];
    const allPointsProvided = points.every((p) => data[p] !== "");

    if (!allPointsProvided) {
      Swal.fire("Error", "Please provide points for all options", "error");
      return;
    }

    if (
      (data.type === "TIU" || data.type === "TWK") &&
      !points.some((p) => data[p] === "5")
    ) {
      Swal.fire(
        "Error",
        "For 'TWK' and 'TIU' questions, one option must have a point value of 5",
        "error"
      );
      return;
    }

    try {
      if (id) {
        await axios.put(`/questions/${id}`, data);
        Swal.fire("Success", "Question updated successfully", "success");
      } else {
        await axios.post(`/questions/exam/${data.examId}`, data);
        Swal.fire("Success", "Question added successfully", "success");
      }
      navigate(`/dashboard/exams/${data.examId}/questions/list`);
    } catch (error) {
      console.error("Failed to submit question", error);
      Swal.fire("Error", "Failed to submit question", "error");
    }
  };

  const handleQuillChange = (name, content) => {
    if (quillRefs.current[name]) {
      setData((prevData) => {
        if (prevData[name] !== content) {
          return { ...prevData, [name]: content };
        }
        return prevData;
      });
    } else {
      quillRefs.current[name] = true;
    }
  };

  const handlePointChange = (option, value) => {
    setData((prevData) => {
      const newData = {
        ...prevData,
        [`point${option}`]: value,
      };

      if (newData.type === "TIU" || newData.type === "TWK") {
        if (value === "5") {
          ["A", "B", "C", "D", "E"].forEach((opt) => {
            if (opt !== option) {
              newData[`point${opt}`] = "0";
            }
          });
        }
      }

      if (newData.type === "TKP") {
        const selectedPoints = new Set(
          ["A", "B", "C", "D", "E"]
            .map((opt) => newData[`point${opt}`])
            .filter(Boolean)
        );

        const points = ["1", "2", "3", "4", "5"];

        const newAvailablePoints = {};

        ["A", "B", "C", "D", "E"].forEach((opt) => {
          const available = points.filter(
            (point) =>
              !selectedPoints.has(point) || newData[`point${opt}`] === point
          );
          newAvailablePoints[opt] = available;
        });

        setAvailablePoints(newAvailablePoints);
      }

      return newData;
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container className="form-container">
      <h2>{id ? "Edit Question" : "Add New Question"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formExamId" className="mt-3">
          <Form.Label>Exam</Form.Label>
          <Form.Control
            as="select"
            name="examId"
            value={data.examId}
            onChange={(e) => setData({ ...data, examId: e.target.value })}
            required
          >
            <option value="">Select an exam</option>
            {exams.map((exam) => (
              <option key={exam.id} value={exam.id}>
                {exam.title}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formQuestionText" className="mt-3">
          <Form.Label>Question Text</Form.Label>
          <ReactQuill
            theme="snow"
            value={data.questionText}
            onChange={(content) => handleQuillChange("questionText", content)}
            placeholder="Write your question here..."
            className="quill-editor"
          />
        </Form.Group>

        <Form.Group controlId="formType" className="mt-3">
          <Form.Label>Question Type</Form.Label>
          <Form.Control
            as="select"
            name="type"
            value={data.type}
            onChange={(e) => setData({ ...data, type: e.target.value })}
            required
          >
            <option value="">Select question type</option>
            <option value="TIU">TIU</option>
            <option value="TWK">TWK</option>
            <option value="TKP">TKP</option>
          </Form.Control>
        </Form.Group>

        {["A", "B", "C", "D", "E"].map((option) => (
          <div key={option}>
            <Form.Group controlId={`formOption${option}`} className="mt-3">
              <Form.Label>Option {option}</Form.Label>
              <ReactQuill
                theme="snow"
                value={data[`option${option}`]}
                onChange={(content) =>
                  handleQuillChange(`option${option}`, content)
                }
                placeholder={`Write option ${option} here...`}
                className="quill-editor"
              />
            </Form.Group>

            <Form.Group controlId={`formPoint${option}`} className="mt-3">
              <Form.Label>Point for Option {option}</Form.Label>
              <Form.Control
                as="select"
                name={`point${option}`}
                value={data[`point${option}`]}
                onChange={(e) => handlePointChange(option, e.target.value)}
                required
              >
                <option value="">Select point</option>
                {availablePoints[option].map((point) => (
                  <option key={point} value={point}>
                    {point}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        ))}

        <Form.Group controlId="formDiscussion" className="mt-3">
          <Form.Label>Discussion</Form.Label>
          <ReactQuill
            theme="snow"
            value={data.discussion}
            onChange={(content) => handleQuillChange("discussion", content)}
            placeholder="Write discussion here..."
            className="quill-editor"
          />
        </Form.Group>

        <Button type="submit" className="mt-4 action-button" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default QuestionForm;
