import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../styles/Footer.css";

function CustomFooter() {
  return (
    <footer className="custom-footer">
      <Container>
        <Row>
          <Col md={6}>
            <p>&copy; 2024 Belajar CPNS Bersama</p>
          </Col>
          <Col md={6} className="text-md-end"></Col>
        </Row>
      </Container>
    </footer>
  );
}

export default CustomFooter;
