import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button, Row, Col, Card } from "react-bootstrap";
import Swal from "sweetalert2";

function Register() {
  const [data, setData] = useState({
    username: "",
    email: "",
    password: "",
    role: "student",
    image: null,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "image") {
      setData({ ...data, image: files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmail(data.email)) {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address",
        icon: "error",
        background: "#f8f9fa",
      });
      return;
    }

    if (data.password.length < 6) {
      Swal.fire({
        title: "Invalid Password",
        text: "Password must be at least 6 characters",
        icon: "error",
        background: "#f8f9fa",
      });
      return;
    }

    const formData = new FormData();
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("password", data.password);
    formData.append("role", data.role);

    if (data.image) {
      formData.append("image", data.image);
    }

    try {
      await axios.post("/users/register", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      Swal.fire({
        title: "Registration Successful",
        text: "You can now log in",
        icon: "success",
        background: "#f8f9fa",
      });
      navigate("/login");
    } catch (error) {
      Swal.fire({
        title: "Registration Failed",
        text: error.response.data.message,
        icon: "error",
        background: "#f8f9fa",
      });
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#f0f4f8",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "5%",
        paddingBottom: "5%",
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={6} lg={4}>
            <Card
              className="shadow"
              style={{
                borderRadius: "10px",
                backgroundColor: "#e3f2fd",
                padding: "20px",
              }}
            >
              <Card.Body>
                <h2 className="text-center mb-4" style={{ color: "#6c757d" }}>
                  Register
                </h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="formUsername" className="mt-3">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      value={data.username}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={data.email}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formPassword" className="mt-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={data.password}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formImage" className="mt-3">
                    <Form.Label>Profile Image</Form.Label>
                    <Form.Control
                      type="file"
                      name="image"
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Button
                    type="submit"
                    className="w-100 mt-4"
                    variant="primary"
                  >
                    Register
                  </Button>
                  <div className="text-center mt-3">
                    <span>Already have an account? </span>
                    <a
                      href="/login"
                      className="text-decoration-none"
                      style={{ color: "#007bff" }}
                    >
                      Login here
                    </a>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Register;
