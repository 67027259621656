import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col, Spinner } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../styles/Admin.css";
import { debounce } from "lodash";

function ListMaterials() {
  const [materials, setMaterials] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    const handleResize = debounce(() => {
      setIsMobile(window.innerWidth < 768);
    }, 200);

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchMaterials = async () => {
    try {
      const response = await axios.get("/materials");
      setMaterials(response.data);
    } catch (error) {
      if (error.response) {
        setError(
          error.response.data.message ||
            "Failed to load materials. Please try again later."
        );
      } else {
        setError("Network error. Please check your connection.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/materials/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/materials/${id}`);
          setMaterials((prevMaterials) =>
            prevMaterials.filter((material) => material.id !== id)
          );
          Swal.fire("Deleted!", "The material has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete material", error);
          Swal.fire("Error", "Failed to delete material", "error");
        }
      }
    });
  };

  const renderTable = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Content</th>
          <th>Subject Name</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan="5" className="text-center">
              <Spinner animation="border" variant="primary" />
              <p>Loading materials...</p>
            </td>
          </tr>
        ) : materials.length === 0 ? (
          <tr>
            <td colSpan="5" className="text-center">
              <p>Belum ada materi yang tersedia.</p>
            </td>
          </tr>
        ) : (
          materials.map((material) => (
            <tr key={material.id}>
              <td>{material.id}</td>
              <td>{material.title}</td>
              <td>
                {material.content.length > 100
                  ? material.content.substring(0, 100) + "..."
                  : material.content}
              </td>
              <td>{material.Subject.name}</td>
              <td>
                <Button
                  variant="warning"
                  onClick={() => handleEdit(material.id)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => handleDelete(material.id)}
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );

  const renderCards = () => (
    <Row>
      {materials.length === 0 ? (
        <Col xs={12}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Belum ada materi</Card.Title>
              <Card.Text>
                Materi akan muncul di sini setelah ditambahkan.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ) : (
        materials.map((material) => (
          <Col key={material.id} xs={12} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>{material.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {material.Subject.name}
                </Card.Subtitle>
                <Card.Text>
                  <strong>Content:</strong>{" "}
                  {material.content.length > 100
                    ? material.content.substring(0, 100) + "..."
                    : material.content}
                </Card.Text>
                <div className="d-flex flex-wrap">
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleEdit(material.id)}
                    className="me-2 mb-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(material.id)}
                    className="mb-2"
                  >
                    Delete
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );

  return (
    <div>
      <h2>Materials List</h2>
      {error ? (
        <div className="text-center text-danger">
          <p>{error}</p>
        </div>
      ) : loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="primary" />
          <p>Loading materials...</p>
        </div>
      ) : isMobile ? (
        renderCards()
      ) : (
        renderTable()
      )}
    </div>
  );
}

export default ListMaterials;
