import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import config from "../config";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [role, setRole] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const storedToken = localStorage.getItem("token");
      if (storedToken) {
        try {
          const decodedToken = jwtDecode(storedToken);
          const { userId } = decodedToken;
          const response = await axios.get(
            `${config.apiBaseUrl}/users/${userId}`,
            {
              headers: {
                Authorization: `Bearer ${storedToken}`,
              },
            }
          );
          setIsAuthenticated(true);
          setRole(response.data.role);
          setUser(response.data);
          setToken(storedToken);
        } catch (error) {
          console.error("Error fetching user data:", error);
          localStorage.removeItem("token");
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const login = async (login, password) => {
    try {
      const response = await axios.post(`${config.apiBaseUrl}/users/login`, {
        login,
        password,
      });
      localStorage.setItem("token", response.data.token);
      const decodedToken = jwtDecode(response.data.token);
      setIsAuthenticated(true);
      setRole(decodedToken.role);
      setUser(response.data.user);
      setToken(response.data.token);
      return response.data;
    } catch (error) {
      console.error(
        "Login failed",
        error.response?.data?.message || error.message
      );
      throw error;
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    setRole(null);
    setUser(null);
    setToken(null);
  };

  const updateProfile = (updatedUser) => {
    setUser(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        role,
        isAuthenticated,
        isLoading,
        login,
        logout,
        token,
        updateProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
