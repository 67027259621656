import React, { useEffect, useState, useCallback } from "react";
import axios from "../axiosConfig";
import { Card, Container, Spinner, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../styles/MaterialDetails.css";

function MaterialDetails() {
  const { id } = useParams();
  const [material, setMaterial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMaterialDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/materials/${id}`);
      setMaterial(response.data);
    } catch (error) {
      console.error("Gagal mengambil detail material", error);
      setError("Gagal mengambil detail material. Silakan coba lagi nanti.");
    } finally {
      setLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchMaterialDetails();
  }, [fetchMaterialDetails]);

  if (loading) {
    return (
      <Container className="mt-4 text-center">
        <Spinner animation="border" variant="primary" />
        <p className="mt-2">Memuat...</p>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <Alert variant="danger">{error}</Alert>
      </Container>
    );
  }

  return (
    <Container className="mt-4">
      <Card>
        <Card.Header as="h2" className="bg-primary text-white">
          {material.title}
        </Card.Header>
        <Card.Body>
          <div
            className="material-content"
            dangerouslySetInnerHTML={{ __html: material.content }}
            style={{ whiteSpace: "pre-wrap" }}
          />
        </Card.Body>
        <Card.Footer className="text-muted">
          Terakhir diperbarui pada:{" "}
          {new Date(material.updatedAt).toLocaleDateString()}
        </Card.Footer>
      </Card>
    </Container>
  );
}

export default MaterialDetails;
