import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Container } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../styles/Admin.css";

function EditUser() {
  const { id } = useParams();
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const navigate = useNavigate();

  const fetchUserData = useCallback(async () => {
    try {
      const response = await axios.get(`/users/${id}`);
      const user = response.data;
      setUsername(user.username);
      setEmail(user.email);
      setRole(user.role);
    } catch (error) {
      console.error("Failed to fetch user data", error);
    }
  }, [id]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("username", username);
    formData.append("email", email);
    formData.append("role", role);
    if (image) {
      formData.append("image", image);
    }

    try {
      await axios.put(`/users/admin/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      Swal.fire("Success", "User updated successfully", "success").then(() => {
        navigate("/dashboard/users/list");
      });
    } catch (error) {
      console.error("Failed to update user", error);
      Swal.fire("Error", "Failed to update user", "error");
    }
  };

  return (
    <Container className="form-container">
      <h2>Edit User</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="username" className="mt-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="email" className="mt-3">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Form.Group>
        <Form.Group controlId="role" className="mt-3">
          <Form.Label>Role</Form.Label>
          <Form.Control
            as="select"
            value={role}
            onChange={(e) => setRole(e.target.value)}
            required
          >
            <option value="">Select role</option>
            <option value="student">Student</option>
            <option value="teacher">Teacher</option>
            <option value="admin">Admin</option>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="image" className="mt-3">
          <Form.Label>Profile Picture</Form.Label>
          <Form.Control type="file" onChange={handleImageChange} />
        </Form.Group>
        <Button type="submit" className="mt-4 action-button" variant="primary">
          Update User
        </Button>
      </Form>
    </Container>
  );
}

export default EditUser;
