const config = {
  apiBaseUrl:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_API_BASE_URL_DEVELOPMENT
      : process.env.REACT_APP_API_BASE_URL_PRODUCTION,

  imageBaseUrl:
    process.env.NODE_ENV === "development"
      ? process.env.REACT_APP_IMAGE_BASE_URL_DEVELOPMENT
      : process.env.REACT_APP_IMAGE_BASE_URL_PRODUCTION,
};

export default config;
