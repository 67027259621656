import React, { useEffect, useState, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "../../axiosConfig";
import Swal from "sweetalert2";
import { Card } from "react-bootstrap";
import DOMPurify from "dompurify";

function QuestionDetail() {
  const { examId } = useParams();
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const navigate = useNavigate();

  const fetchQuestions = useCallback(async () => {
    try {
      const questionResponse = await axios.get(`/questions/exam/${examId}`);
      setQuestions(questionResponse.data);

      const answerResponse = await axios.get(`/results/${examId}/answers`);
      setUserAnswers(answerResponse.data.answers || {});
    } catch (error) {
      if (error.response && error.response.status === 403) {
        Swal.fire(
          "Access Denied",
          "You have not completed this exam.",
          "error"
        );
        navigate("/dashboard/results/list");
      } else {
        console.error("Failed to fetch questions or answers", error);
      }
    }
  }, [examId, navigate]);

  useEffect(() => {
    fetchQuestions();
  }, [examId, fetchQuestions]);

  const getAnswerColor = (pointsEarned) => {
    switch (pointsEarned) {
      case 0:
        return "#FF0000";
      case 1:
        return "#FF4500";
      case 2:
        return "#FFA500";
      case 3:
        return "#FFFF00";
      case 4:
        return "#32CD32";
      case 5:
        return "#0000FF";
      default:
        return "#000000";
    }
  };

  const renderAnswerComparison = (question) => {
    const userAnswer = userAnswers[question.id] || "N/A";
    let pointsEarned = 0;

    if (question.type === "TKP") {
      switch (userAnswer) {
        case "A":
          pointsEarned = question.pointA;
          break;
        case "B":
          pointsEarned = question.pointB;
          break;
        case "C":
          pointsEarned = question.pointC;
          break;
        case "D":
          pointsEarned = question.pointD;
          break;
        case "E":
          pointsEarned = question.pointE;
          break;
        default:
          pointsEarned = 0;
      }
    } else if (question.type === "TIU" || question.type === "TWK") {
      switch (userAnswer) {
        case "A":
          pointsEarned = question.pointA;
          break;
        case "B":
          pointsEarned = question.pointB;
          break;
        case "C":
          pointsEarned = question.pointC;
          break;
        case "D":
          pointsEarned = question.pointD;
          break;
        case "E":
          pointsEarned = question.pointE;
          break;
        default:
          pointsEarned = 0;
      }
    }
    const answerColor = getAnswerColor(pointsEarned);

    return { userAnswer, pointsEarned, answerColor };
  };

  return (
    <div>
      <h2>Questions and Explanations</h2>
      {questions.length > 0 ? (
        <div>
          {questions.map((question, index) => {
            const { userAnswer, pointsEarned, answerColor } =
              renderAnswerComparison(question);

            return (
              <Card key={question.id} className="mb-4">
                <Card.Body>
                  <Card.Title>
                    <strong>Question {index + 1}:</strong>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(question.questionText),
                      }}
                    />
                  </Card.Title>
                  <Card.Text>
                    <strong>Options:</strong>
                    <p>
                      <strong>A. </strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.optionA),
                        }}
                      />
                      <span> (Points: {question.pointA})</span>
                    </p>
                    <p>
                      <strong>B. </strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.optionB),
                        }}
                      />
                      <span> (Points: {question.pointB})</span>
                    </p>
                    <p>
                      <strong>C. </strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.optionC),
                        }}
                      />
                      <span> (Points: {question.pointC})</span>
                    </p>
                    <p>
                      <strong>D. </strong>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.optionD),
                        }}
                      />
                      <span> (Points: {question.pointD})</span>
                    </p>

                    {question.optionE && (
                      <p>
                        <strong>E. </strong>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(question.optionE),
                          }}
                        />
                        <span> (Points: {question.pointE})</span>
                      </p>
                    )}
                  </Card.Text>

                  <p style={{ color: answerColor }}>
                    <strong>Your Answer:</strong> {userAnswer} ({pointsEarned}{" "}
                    Points)
                  </p>

                  <Card.Footer>
                    <p>
                      <strong>Discussion:</strong>{" "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(question.discussion),
                        }}
                      />
                    </p>
                  </Card.Footer>
                </Card.Body>
              </Card>
            );
          })}
        </div>
      ) : (
        <p>No questions found.</p>
      )}
    </div>
  );
}

export default QuestionDetail;
