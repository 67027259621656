import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../styles/Sidebar.css";
import { useAuth } from "../context/AuthContext";
import {
  FaChevronRight,
  FaChevronDown,
  FaUser,
  FaBook,
  FaEdit,
  FaClipboardList,
  FaPlus,
  FaHome,
} from "react-icons/fa";

function Sidebar({ setSidebarOpen }) {
  const navigate = useNavigate();
  const { role } = useAuth();
  const [openSection, setOpenSection] = useState("");
  const [openSubMenu, setOpenSubMenu] = useState("");

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? "" : section);
    if (section !== "masterData") {
      setOpenSubMenu("");
    }
  };

  const toggleSubMenu = (submenu) => {
    setOpenSubMenu(openSubMenu === submenu ? "" : submenu);
  };

  const handleNavItemClick = (path) => {
    navigate(path);
    setSidebarOpen(false);
  };

  return (
    <div className={`sidebar d-flex flex-column bg-light vh-100`}>
      <div className="sidebar-header p-3 text-center">
        <h5>Belajar CPNS Bersama</h5>
      </div>
      <div className="sidebar-content p-3">
        <Nav className="flex-column">
          <Nav.Item>
            <Nav.Link
              onClick={() => handleNavItemClick("/dashboard")}
              className="d-flex align-items-center dashboard"
            >
              <FaBook className="sidebar-icon" /> Dashboard
            </Nav.Link>
          </Nav.Item>
          {role === "admin" && (
            <>
              <Nav.Item>
                <Nav.Link
                  onClick={() => toggleSection("userManagement")}
                  className="d-flex align-items-center"
                >
                  <FaUser className="sidebar-icon" /> User Management
                  {openSection === "userManagement" ? (
                    <FaChevronDown className="chevron-icon" />
                  ) : (
                    <FaChevronRight className="chevron-icon" />
                  )}
                </Nav.Link>
                {openSection === "userManagement" && (
                  <div className="submenu pl-4">
                    <Nav.Link
                      onClick={() =>
                        handleNavItemClick("/dashboard/users/list")
                      }
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> List Users
                    </Nav.Link>
                    <Nav.Link
                      onClick={() => handleNavItemClick("/dashboard/users/new")}
                      className="d-flex align-items-center"
                    >
                      <FaPlus className="sidebar-icon" /> Add User
                    </Nav.Link>
                  </div>
                )}
              </Nav.Item>

              <Nav.Item>
                <Nav.Link
                  onClick={() => toggleSection("masterData")}
                  className="d-flex align-items-center"
                >
                  <FaEdit className="sidebar-icon" /> Master Data
                  {openSection === "masterData" ? (
                    <FaChevronDown className="chevron-icon" />
                  ) : (
                    <FaChevronRight className="chevron-icon" />
                  )}
                </Nav.Link>
                {openSection === "masterData" && (
                  <div className="submenu pl-4">
                    <Nav.Link
                      onClick={() => toggleSubMenu("subjects")}
                      className="d-flex align-items-center"
                    >
                      <FaBook className="sidebar-icon" /> Subjects
                      {openSubMenu === "subjects" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "subjects" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/subjects/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Subjects
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/subjects/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Subjects
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("materials")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Materials
                      {openSubMenu === "materials" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "materials" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/materials/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Materials
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/materials/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Materials
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("exams")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Exams
                      {openSubMenu === "exams" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "exams" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/exams/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Exams
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/exams/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Exams
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("results")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Results
                      {openSubMenu === "results" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "results" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/results/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Results
                        </Nav.Link>
                      </div>
                    )}
                  </div>
                )}
              </Nav.Item>
            </>
          )}
          {role === "teacher" && (
            <>
              <Nav.Item>
                <Nav.Link
                  onClick={() => toggleSection("masterData")}
                  className="d-flex align-items-center"
                >
                  <FaEdit className="sidebar-icon" /> Master Data
                  {openSection === "masterData" ? (
                    <FaChevronDown className="chevron-icon" />
                  ) : (
                    <FaChevronRight className="chevron-icon" />
                  )}
                </Nav.Link>
                {openSection === "masterData" && (
                  <div className="submenu pl-4">
                    <Nav.Link
                      onClick={() => toggleSubMenu("subjects")}
                      className="d-flex align-items-center"
                    >
                      <FaBook className="sidebar-icon" /> Subjects
                      {openSubMenu === "subjects" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "subjects" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/subjects/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Subjects
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/subjects/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Subjects
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("materials")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Materials
                      {openSubMenu === "materials" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "materials" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/materials/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Materials
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/materials/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Materials
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("exams")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Exams
                      {openSubMenu === "exams" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "exams" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/exams/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Exams
                        </Nav.Link>
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/exams/new")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaPlus className="sidebar-icon" /> Add Exams
                        </Nav.Link>
                      </div>
                    )}
                    <Nav.Link
                      onClick={() => toggleSubMenu("results")}
                      className="d-flex align-items-center"
                    >
                      <FaClipboardList className="sidebar-icon" /> Results
                      {openSubMenu === "results" ? (
                        <FaChevronDown className="chevron-icon" />
                      ) : (
                        <FaChevronRight className="chevron-icon" />
                      )}
                    </Nav.Link>
                    {openSubMenu === "results" && (
                      <div className="submenu2 pl-4">
                        <Nav.Link
                          onClick={() =>
                            handleNavItemClick("/dashboard/results/list")
                          }
                          className="d-flex align-items-center"
                        >
                          <FaClipboardList className="sidebar-icon" /> List
                          Results
                        </Nav.Link>
                      </div>
                    )}
                  </div>
                )}
              </Nav.Item>
            </>
          )}

          {role === "student" && (
            <Nav.Item>
              <Nav.Link
                onClick={() => toggleSection("results")}
                className="d-flex align-items-center"
              >
                <FaClipboardList className="sidebar-icon" /> Results
                {openSection === "results" ? (
                  <FaChevronDown className="chevron-icon" />
                ) : (
                  <FaChevronRight className="chevron-icon" />
                )}
              </Nav.Link>
              {openSection === "results" && (
                <div className="submenu pl-4">
                  <Nav.Link
                    onClick={() =>
                      handleNavItemClick("/dashboard/results/list")
                    }
                    className="d-flex align-items-center"
                  >
                    <FaClipboardList className="sidebar-icon" /> List Results
                  </Nav.Link>
                </div>
              )}
              <Nav.Link
                onClick={() => handleNavItemClick("/")}
                className="d-flex align-items-center"
              >
                <FaHome className="sidebar-icon" /> Home
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
      </div>
    </div>
  );
}

export default Sidebar;
