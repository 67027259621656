import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../axiosConfig";
import { Container, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";
import "../../styles/Admin.css";

function ExamForm() {
  const [data, setData] = useState({
    title: "",
    subjectId: "",
    duration: 0,
    minTWK: 0,
    minTIU: 0,
    minTKP: 0,
  });
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`/exams/${id}`);
      setData(response.data);
    } catch (error) {
      console.error("Failed to fetch exam", error);
    }
  }, [id]);

  useEffect(() => {
    fetchSubjects();
    if (id) {
      fetchData();
    }
  }, [id, fetchData]);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (id) {
        await axios.put(`/exams/${id}`, data);
        Swal.fire("Success", "Exam updated successfully", "success");
      } else {
        await axios.post(`/exams`, data);
        Swal.fire("Success", "Exam added successfully", "success");
      }
      navigate("/dashboard/exams/list");
    } catch (error) {
      console.error("Failed to submit exam", error);
      Swal.fire("Error", "Failed to submit exam", "error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]:
        name === "duration" || name.startsWith("min")
          ? parseInt(value, 10)
          : value,
    }));
  };

  return (
    <Container className="form-container">
      <h2>{id ? "Edit Exam" : "Add New Exam"}</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formSubjectId" className="mt-3">
          <Form.Label>Subject</Form.Label>
          <Form.Control
            as="select"
            name="subjectId"
            value={data.subjectId}
            onChange={handleChange}
            required
          >
            <option value="">Select a subject</option>
            {subjects.map((subject) => (
              <option key={subject.id} value={subject.id}>
                {subject.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="formTitle" className="mt-3">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="title"
            value={data.title}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="formDuration" className="mt-3">
          <Form.Label>Duration (in minutes)</Form.Label>
          <Form.Control
            type="number"
            name="duration"
            value={data.duration}
            onChange={handleChange}
            min="1"
            required
          />
        </Form.Group>

        <Form.Group controlId="formMinTWK" className="mt-3">
          <Form.Label>Minimum TWK Score</Form.Label>
          <Form.Control
            type="number"
            name="minTWK"
            value={data.minTWK}
            onChange={handleChange}
            min="0"
            required
          />
        </Form.Group>

        <Form.Group controlId="formMinTIU" className="mt-3">
          <Form.Label>Minimum TIU Score</Form.Label>
          <Form.Control
            type="number"
            name="minTIU"
            value={data.minTIU}
            onChange={handleChange}
            min="0"
            required
          />
        </Form.Group>

        <Form.Group controlId="formMinTKP" className="mt-3">
          <Form.Label>Minimum TKP Score</Form.Label>
          <Form.Control
            type="number"
            name="minTKP"
            value={data.minTKP}
            onChange={handleChange}
            min="0"
            required
          />
        </Form.Group>

        <Button type="submit" className="mt-4 action-button" variant="primary">
          {id ? "Update" : "Add"}
        </Button>
      </Form>
    </Container>
  );
}

export default ExamForm;
