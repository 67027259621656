import React, { useState, useEffect } from "react";
import { FaSun } from "react-icons/fa";
import "../styles/welcome.css";
import { useAuth } from "../context/AuthContext";
function DashboardWelcome() {
  const [currentTime, setCurrentTime] = useState(new Date());
  const { user } = useAuth();
  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timerId);
  }, []);
  const formatDate = currentTime.toLocaleDateString("en-GB", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const formatTime = currentTime.toLocaleTimeString("en-GB");

  return (
    <div className="dashboard-card">
      <div className="greeting-section">
        <FaSun className="weather-icon" />
        <div>
          <p className="greeting-text">
            Hello, Good afternoon, {user?.username}
          </p>
          <p className="date-time">
            {formatDate} <br />
            {formatTime}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DashboardWelcome;
