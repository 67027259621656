import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Swal from "sweetalert2";
import "../../styles/Admin.css";

function ListResults() {
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const { role } = useAuth();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    fetchResults();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchResults = async () => {
    try {
      const response = await axios.get("/results");
      setResults(response.data);
    } catch (error) {
      console.error("Failed to fetch results", error);
    }
  };

  const handleDiscussion = (examId) => {
    navigate(`/dashboard/exams/${examId}/questions/detail`);
  };

  const handleDelete = (resultId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/results/${resultId}`);
          setResults((prevResults) =>
            prevResults.filter((result) => result.id !== resultId)
          );
          Swal.fire("Deleted!", "The result has been deleted.", "success");
        } catch (error) {
          Swal.fire("Failed!", "Failed to delete result.", "error");
          console.error("Failed to delete result", error);
        }
      }
    });
  };

  return (
    <div className="">
      <h2>Results List</h2>

      {results.length === 0 ? (
        <Card className="text-center">
          <Card.Body>
            <Card.Title>Belum ada hasil ujian</Card.Title>
            <Card.Text>
              Hasil ujian akan muncul di sini setelah ujian dilakukan.
            </Card.Text>
          </Card.Body>
        </Card>
      ) : !isMobile ? (
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Id</th>
                <th>Username</th>
                <th>Exam Title</th>
                <th>Min TIU</th>
                <th>Max TIU</th>
                <th>Total TIU</th>
                <th>Min TKP</th>
                <th>Max TKP</th>
                <th>Total TKP</th>
                <th>Min TWK</th>
                <th>Max TWK</th>
                <th>Total TWK</th>
                <th>Taken At</th>
                {role === "student" && <th>Discussion</th>}
                {(role === "admin" || role === "teacher") && <th>Delete</th>}
              </tr>
            </thead>
            <tbody>
              {results.map((result) => (
                <tr key={result.id}>
                  <td>{result.id}</td>
                  <td>{result.User ? result.User.username : "N/A"}</td>
                  <td>{result.Exam ? result.Exam.title : "N/A"}</td>
                  <td>{result.Exam ? result.Exam.minTIU : "N/A"}</td>
                  <td>{result.Exam ? result.Exam.maxTIU : "N/A"}</td>
                  <td>{result.totalTIU}</td>
                  <td>{result.Exam ? result.Exam.minTKP : "N/A"}</td>
                  <td>{result.Exam ? result.Exam.maxTKP : "N/A"}</td>
                  <td>{result.totalTKP}</td>
                  <td>{result.Exam ? result.Exam.minTWK : "N/A"}</td>
                  <td>{result.Exam ? result.Exam.maxTWK : "N/A"}</td>
                  <td>{result.totalTWK}</td>
                  <td>{new Date(result.takenAt).toLocaleString()}</td>
                  {role === "student" && (
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => handleDiscussion(result.examId)}
                      >
                        Discussion
                      </Button>
                    </td>
                  )}
                  {(role === "admin" || role === "teacher") && (
                    <td>
                      <Button
                        variant="danger"
                        onClick={() => handleDelete(result.id)}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <Row>
          {results.map((result) => (
            <Col key={result.id} xs={12} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>
                    {result.User ? result.User.username : "N/A"}
                  </Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {result.Exam ? result.Exam.title : "N/A"}
                  </Card.Subtitle>
                  <Card.Text>
                    <strong>TIU:</strong> {result.totalTIU} (Min:{" "}
                    {result.Exam ? result.Exam.minTIU : "N/A"}, Max:{" "}
                    {result.Exam ? result.Exam.maxTIU : "N/A"})<br />
                    <strong>TKP:</strong> {result.totalTKP} (Min:{" "}
                    {result.Exam ? result.Exam.minTKP : "N/A"}, Max:{" "}
                    {result.Exam ? result.Exam.maxTKP : "N/A"})<br />
                    <strong>TWK:</strong> {result.totalTWK} (Min:{" "}
                    {result.Exam ? result.Exam.minTWK : "N/A"}, Max:{" "}
                    {result.Exam ? result.Exam.maxTWK : "N/A"})<br />
                    <strong>Taken At:</strong>{" "}
                    {new Date(result.takenAt).toLocaleString()}
                  </Card.Text>
                  {role === "student" && (
                    <Button
                      variant="primary"
                      onClick={() => handleDiscussion(result.examId)}
                    >
                      Discussion
                    </Button>
                  )}
                  {(role === "admin" || role === "teacher") && (
                    <Button
                      variant="danger"
                      onClick={() => handleDelete(result.id)}
                    >
                      Delete
                    </Button>
                  )}
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default ListResults;
