import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "react-bootstrap";
import axios from "../../axiosConfig";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import "../../styles/Admin.css";

function ListExams() {
  const [exams, setExams] = useState([]);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    fetchExams();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchExams = async () => {
    try {
      const response = await axios.get("/exams");
      setExams(response.data);
    } catch (error) {
      console.error("Failed to fetch exams", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/exams/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/exams/${id}`);
          fetchExams();
          Swal.fire("Deleted!", "The exam has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete exam", error);
          Swal.fire("Error", "Failed to delete exam", "error");
        }
      }
    });
  };

  const handleListQuestions = (examId) =>
    navigate(`/dashboard/exams/${examId}/questions/list`);

  const handleAddQuestion = (examId) =>
    navigate(`/dashboard/exams/${examId}/questions/new`);

  const renderTable = () => (
    <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>Id</th>
          <th>Title</th>
          <th>Subject Name</th>
          <th>Min TWK</th>
          <th>Min TIU</th>
          <th>Min TKP</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {exams.length === 0 ? (
          <tr>
            <td colSpan="7" className="text-center">
              <p>Belum ada ujian yang tersedia.</p>
            </td>
          </tr>
        ) : (
          exams.map((exam) => (
            <tr key={exam.id}>
              <td>{exam.id}</td>
              <td>{exam.title}</td>
              <td>{exam.Subject.name}</td>
              <td>{exam.minTWK}</td>
              <td>{exam.minTIU}</td>
              <td>{exam.minTKP}</td>
              <td>
                <Button
                  variant="warning"
                  size="sm"
                  onClick={() => handleEdit(exam.id)}
                  className="me-2"
                >
                  Edit
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleDelete(exam.id)}
                  className="me-2"
                >
                  Delete
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => handleListQuestions(exam.id)}
                  className="me-2"
                >
                  List Questions
                </Button>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => handleAddQuestion(exam.id)}
                >
                  Add Question
                </Button>
              </td>
            </tr>
          ))
        )}
      </tbody>
    </Table>
  );

  const renderCards = () => (
    <Row>
      {exams.length === 0 ? (
        <Col xs={12}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title>Belum ada ujian</Card.Title>
              <Card.Text>
                Ujian akan muncul di sini setelah ditambahkan.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ) : (
        exams.map((exam) => (
          <Col key={exam.id} xs={12} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>{exam.title}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  {exam.Subject.name}
                </Card.Subtitle>
                <Card.Text>
                  <strong>Min TWK:</strong> {exam.minTWK}
                  <br />
                  <strong>Min TIU:</strong> {exam.minTIU}
                  <br />
                  <strong>Min TKP:</strong> {exam.minTKP}
                </Card.Text>
                <div className="d-flex flex-wrap">
                  <Button
                    variant="warning"
                    size="sm"
                    onClick={() => handleEdit(exam.id)}
                    className="me-2 mb-2"
                  >
                    Edit
                  </Button>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleDelete(exam.id)}
                    className="me-2 mb-2"
                  >
                    Delete
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => handleListQuestions(exam.id)}
                    className="me-2 mb-2"
                  >
                    List Questions
                  </Button>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => handleAddQuestion(exam.id)}
                    className="mb-2"
                  >
                    Add Question
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))
      )}
    </Row>
  );

  return (
    <div>
      <h2>Exams List</h2>
      {isMobile ? renderCards() : renderTable()}
    </div>
  );
}

export default ListExams;
