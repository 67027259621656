import React, { useEffect, useState, useCallback } from "react";
import axios from "../axiosConfig";
import { Card, Container, Row, Col, Spinner } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CustomNavbar from "../components/CustomNavbar";
import CustomFooter from "../components/CustomFooter";
import Slider from "react-slick";
import "../styles/SubjectDetails.css";

function SubjectDetails() {
  const { id } = useParams();
  const [subject, setSubject] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const fetchSubjectDetails = useCallback(async () => {
    try {
      const response = await axios.get(`/subjects/${id}`);
      setSubject(response.data);
    } catch (error) {
      console.error("Gagal mengambil detail mata pelajaran", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Gagal mengambil detail mata pelajaran. Silakan coba lagi nanti.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    fetchSubjectDetails();
  }, [fetchSubjectDetails]);

  const handleMaterialClick = (materialId) => {
    navigate(`/materials/${materialId}`);
  };

  const handleExamClick = async (examId) => {
    try {
      await axios.get(`/exams/${examId}`);
      navigate(`/exams/${examId}`);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        Swal.fire({
          icon: "warning",
          title: "Exam Already Taken",
          text: "Anda sudah mengikuti ujian ini!",
          confirmButtonText: "Kembali ke Mata Pelajaran",
        }).then(() => {
          navigate(`/subjects/${id}`);
        });
      } else {
        console.error("Error saat memeriksa ujian:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Terjadi kesalahan saat memeriksa ujian. Silakan coba lagi nanti.",
        });
      }
    }
  };

  if (isLoading) {
    return (
      <div className="loading-spinner">
        <Spinner animation="border" variant="primary" />
        <span className="ms-2">Loading...</span>
      </div>
    );
  }

  if (!subject) {
    return (
      <Container className="mt-4 text-center">
        <p>Detail mata pelajaran tidak ditemukan.</p>
      </Container>
    );
  }

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="page-container">
      <CustomNavbar />
      <main className="main-content">
        <Container className="mt-4">
          <Row>
            <Col>
              <h2 className="mb-4 text-center">{subject.title}</h2>
            </Col>
          </Row>

          <Row>
            <Col>
              <h4 className="mb-3">Materi</h4>
              {subject.Materials.length > 0 ? (
                <Slider {...sliderSettings} className="slider-container">
                  {subject.Materials.map((material) => (
                    <div key={material.id} className="slider-card">
                      <Card
                        className="h-100 material-card"
                        onClick={() => handleMaterialClick(material.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <Card.Body>
                          <Card.Title>{material.title}</Card.Title>
                          <Card.Text>
                            {material.description ||
                              "Deskripsi materi singkat."}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>Tidak ada materi tersedia.</p>
              )}
            </Col>
          </Row>

          <Row className="mt-5">
            <Col>
              <h4 className="mb-3">Ujian</h4>
              {subject.Exams.length > 0 ? (
                <Slider {...sliderSettings} className="slider-container">
                  {subject.Exams.map((exam) => (
                    <div key={exam.id} className="slider-card">
                      <Card
                        className="h-100 exam-card"
                        onClick={() => handleExamClick(exam.id)}
                        style={{ cursor: "pointer" }}
                      >
                        <Card.Body>
                          <Card.Title>{exam.title}</Card.Title>
                          <Card.Text>
                            {exam.description || "Deskripsi ujian singkat."}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </Slider>
              ) : (
                <p>Tidak ada ujian tersedia.</p>
              )}
            </Col>
          </Row>
        </Container>
      </main>
      <CustomFooter />
    </div>
  );
}

export default SubjectDetails;
