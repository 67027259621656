import React, { useEffect, useState } from "react";
import { Table, Button, Card, Row, Col } from "react-bootstrap";
import axios from "../../axiosConfig";
import "../../styles/Admin.css";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function ListSubjects() {
  const [subjects, setSubjects] = useState([]);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    fetchSubjects();
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchSubjects = async () => {
    try {
      const response = await axios.get("/subjects");
      setSubjects(response.data);
    } catch (error) {
      console.error("Failed to fetch subjects", error);
    }
  };

  const handleEdit = (id) => {
    navigate(`/dashboard/subjects/edit/${id}`);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`/subjects/${id}`);
          fetchSubjects();
          Swal.fire("Deleted!", "The subject has been deleted.", "success");
        } catch (error) {
          console.error("Failed to delete subject", error);
          Swal.fire("Error", "Failed to delete subject", "error");
        }
      }
    });
  };

  return (
    <div>
      <h2>Subjects List</h2>

      {!isMobile ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map((subject) => (
              <tr key={subject.id}>
                <td>{subject.id}</td>
                <td>{subject.name}</td>
                <td>{subject.description}</td>
                <td>
                  <div className="action-buttons">
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEdit(subject.id)}
                      className="me-3 action-button"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(subject.id)}
                      className="action-button"
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Row>
          {subjects.map((subject) => (
            <Col key={subject.id} xs={12} className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>{subject.name}</Card.Title>
                  <Card.Text>{subject.description}</Card.Text>
                  <div className="action-buttons">
                    <Button
                      variant="warning"
                      size="sm"
                      onClick={() => handleEdit(subject.id)}
                      className="action-button"
                    >
                      Edit
                    </Button>
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => handleDelete(subject.id)}
                      className="action-button"
                    >
                      Delete
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

export default ListSubjects;
