import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidebar from "./Sidebar";
import TopBar from "./TopBar";
import { Outlet, useLocation } from "react-router-dom";
import DashboardWelcome from "./DashboardWelcome";
import "../styles/Dashboard.css";

function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <Container fluid className="p-0">
      <Row className="vh-100">
        {sidebarOpen && (
          <Col xs={6} md={3} className="p-0 sidebar-container">
            <Sidebar setSidebarOpen={setSidebarOpen} />
          </Col>
        )}
        <Col
          xs={sidebarOpen ? 6 : 12}
          md={sidebarOpen ? 9 : 12}
          className="p-0 main-content"
        >
          <TopBar toggleSidebar={toggleSidebar} sidebarOpen={sidebarOpen} />
          <div
            className={`content p-4 ${
              sidebarOpen && isMobile ? "content-hidden" : ""
            }`}
          >
            {location.pathname === "/dashboard" && <DashboardWelcome />}
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;
